import * as React from "react"

import Subheader from "../../components/content_blocks/special/subheader"
import Layout from "../../components/layout"
import AllLayouts from "../allLayouts"

const Page = ({ pageContext }) => {
  const {
    page: { title, contentBlocks },
  } = pageContext

  const {
    page: { pageSettings },
  } = pageContext

  // create subheader data
  const subheaderdata = {
    heading: title,
    leadingText: pageSettings?.subheaderLeadingText,
    bodyText: pageSettings?.subheaderText,
  }

  const layouts = contentBlocks.contentBlocks || []
  const useLargeHeader = pageSettings?.useLargeHeader

  return (
    <Layout headerData={pageSettings}>
      {!useLargeHeader && <Subheader {...subheaderdata} />}
      {layouts.map((layout, index) => {
        return <AllLayouts key={index} layoutData={layout} />
      })}
    </Layout>
  )
}

export default Page
